

















































































































































import { type PropType, computed, defineComponent, ref, watch } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { format, isBefore, subDays } from "date-fns";
import { clubApi, coachApi, reserveerApi } from "@/lib/backend";
import type { Afspraak } from "@/lib/backend/coach.api";
import { APPOINTMENT_MAP } from "@/lib/constants/options";
import { formatErrorMessage } from "@/lib/formatErrorMessage";
import { getCoachName } from "@/lib/getCoachName";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { queryOptions_coaches } from "@/lib/query/functions/coaches";
import { queryOptions_gyms } from "@/lib/query/functions/gyms";
import { toast } from "@/lib/vue2-sonner";
import { logger } from "@/logger";
import { usePopupStore } from "@/pinia/popup";
import type { RouteProps } from "@/router/types";
import { getDateFromDatum, getDateFromDatumAndTijd } from "@/utils/date";

type Props = RouteProps<"Member appointment edit">;

export default defineComponent({
	props: {
		memberId: {
			type: Number as PropType<Props["memberId"]>,
			required: true,
		},
		appointmentId: {
			type: Number as PropType<Props["appointmentId"]>,
			required: true,
		},
	},
	setup(props, { root }) {
		const TOAST_ID = "afspraak-aanpassen";

		const popupStore = usePopupStore();

		const { data: memberInfo } = useQuery({
			queryKey: ["member", props.memberId, "info"] as const,
			queryFn: async (context) =>
				await clubApi.sportersDashboard
					.getLidInfo(context.queryKey[1], { signal: context.signal })
					.then((response) => response.data),
		});

		const { status: appointmentStatus, data: appointment } = useQuery({
			queryKey: ["member", props.memberId, "appointment", props.appointmentId] as const,
			queryFn: async (context) =>
				await reserveerApi.fitnessafspraken
					.getAfspraak(context.queryKey[3], { signal: context.signal })
					.then((response) => response.data),
		});

		const overridenComments = ref("");
		const overridenToggles = ref<
			| {
					enabled: boolean;
					icon: string;
					shortText: string;
					text: string;
					slug: string;
			  }[]
			| undefined
		>(undefined);
		const data = computed<{
			comments: string;
			toggles: {
				enabled: boolean;
				icon: string;
				shortText: string;
				text: string;
				slug: string;
			}[];
		}>({
			get() {
				return {
					comments: overridenComments.value || appointment.value?.aanwezigheid?.opmerkingen || "",
					toggles:
						overridenToggles.value ||
						Object.entries(APPOINTMENT_MAP).map(([slug, appointmentMap]) => ({
							slug,
							...appointmentMap,
							enabled: (function () {
								switch (slug) {
									case "meting":
										return appointment.value?.aanwezigheid?.meting ?? false;
									case "trainingsSchema":
										return appointment.value?.aanwezigheid?.trainingsSchema ?? false;
									case "doelOpstellen":
										return appointment.value?.aanwezigheid?.doelOpstellen ?? false;
									case "personalTraining":
										return appointment.value?.aanwezigheid?.personalTraining ?? false;
									case "kickOff":
										return appointment.value?.aanwezigheid?.kickOff ?? false;
									default:
										return false;
								}
							})(),
						})),
				};
			},
			set(value) {
				overridenComments.value = value.comments;
				overridenToggles.value = value.toggles;
			},
		});

		const { id: currentGymId } = useCurrentGym();
		const { data: gyms } = useQuery(queryOptions_gyms());

		const overridenGymId = ref<number | undefined>(undefined);
		const gymId = computed<number>({
			get() {
				return overridenGymId.value || appointment.value?.vestigingId || currentGymId.value;
			},
			set(value) {
				overridenGymId.value = value;
			},
		});
		const gym = computed(() => gyms.value?.find((gym) => gym.id === gymId.value));

		const { data: coaches } = useQuery(
			queryOptions_coaches(computed(() => ({ gymId: gymId.value }))),
		);

		const overridenCoachId = ref<number | null | undefined>(undefined);
		const coachId = computed<number | null | undefined>({
			get() {
				if (overridenCoachId.value || overridenCoachId.value === null) {
					return overridenCoachId.value;
				}

				return appointment.value?.trainerId;
			},
			set(value) {
				overridenCoachId.value = value;
			},
		});

		const overridenDate = ref<Date | undefined>(undefined);
		const date = computed<Date>({
			get() {
				return (
					overridenDate.value ??
					(appointment.value?.datum ? new Date(appointment.value.datum) : new Date())
				);
			},
			set(value) {
				overridenDate.value = value;
			},
		});
		const disabledDates = ref({ to: subDays(new Date(), 1) });

		const queryKey = computed(
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
			() => ["afspraken", gym.value?.slug!, format(date.value, "MM-yyyy")] as const,
		);
		const enabled = computed(() => !!gym.value?.slug);
		const { status, data: monthDetail } = useQuery({
			queryKey,
			queryFn: async (context) =>
				await coachApi.api
					.appointmentsMonthDetail(context.queryKey[1], context.queryKey[2], {
						signal: context.signal,
					})
					.then((response) => response.data),
			enabled,
		});
		const slots = computed(() => monthDetail.value?.[format(date.value, "dd-MM-yyyy")] ?? []);

		const overridenSelectedSlot = ref<Afspraak | null | undefined>(undefined);
		const selectedSlot = computed<Afspraak | null | undefined>({
			get() {
				if (overridenSelectedSlot.value === null) {
					return undefined;
				}

				return (
					overridenSelectedSlot.value ??
					slots.value.find((slot) => slot.afspraakId === appointment.value?.id)
				);
			},
			set(value) {
				overridenSelectedSlot.value = value;
			},
		});

		const showKickOff = computed(
			() => appointment.value?.aanwezigheid?.kickOff || !memberInfo.value?.onboardingAfgerond,
		);

		watch([appointmentStatus, appointment], ([status, data]) => {
			if (status !== "success" || !!data) {
				return;
			}

			logger.debug("Appointment not found", props);
			root.$router.push({ name: "Gebruiker" });
		});
		watch(gymId, () => {
			coachId.value = null;
			selectedSlot.value = undefined;
		});
		watch(date, () => (selectedSlot.value = undefined));

		async function submit() {
			try {
				const afspraakId = selectedSlot.value?.afspraakId;

				if (!selectedSlot.value || !afspraakId) {
					throw new Error("Selecteer een afspraak tijdstip");
				}

				if (data.value.toggles.every((toggle) => !toggle.enabled)) {
					throw new Error("Selecteer ten minste één afspraak reden");
				}

				return popupStore.open({
					title: "Let op!",
					body: `Wil je de afspraak aanpassen:
								<p>
									<b>${selectedSlot.value.datum}</b> om <b>${selectedSlot.value.slot}</b> in
									<b>${gym.value?.naam}</b>
									voor
									<b>${appointment.value?.aanwezigheid?.lidNaam}</b>
								</p>
								Tijdens de afspraak worden de volgende punten behandeld:
								<ul>
									${data.value.toggles
										.filter((toggle) => toggle.enabled)
										.map((toggle) => `<li><b>${toggle.text}</b></li>`)
										.join("")}
										</ul>
										Met de volgende opmerking:
										<p>
										<b>${data.value.comments ? data.value.comments : "Geen opmerkingen."}</b>
										</p>`,
					buttons: {
						cancel: "Annuleren",
						confirm: "Bevestigen",
					},
					callback: async () => {
						try {
							toast.loading("Afspraak aanpassen...", { id: TOAST_ID });

							const afspraak = appointment.value;

							if (!afspraak) {
								throw new Error("Afspraak niet gevonden");
							}

							if (afspraak.id !== afspraakId) {
								await reserveerApi.fitnessafspraken.annuleerAfspraak({
									id: afspraak.id,
									reden: "Verplaatst naar een ander tijdstip",
								});

								const response = await coachApi.api.fitnessafsprakenAanwezigheidCreate(afspraakId, {
									lidId: afspraak.aanwezigheid?.lidId ?? props.memberId,
									lidNaam:
										afspraak.aanwezigheid?.lidNaam ??
										`${memberInfo.value?.voornaam} ${memberInfo.value?.achternaam}`,
									status: "aangemeld",
									opmerkingen: data.value.comments,
									isTemporaryMember: false,
									doelOpstellen:
										data.value.toggles.find((toggle) => toggle.slug === "doelOpstellen")?.enabled ??
										false,
									meting:
										data.value.toggles.find((toggle) => toggle.slug === "meting")?.enabled ?? false,
									kickOff:
										data.value.toggles.find((toggle) => toggle.slug === "kickOff")?.enabled ??
										false,
									personalTraining:
										data.value.toggles.find((toggle) => toggle.slug === "personalTraining")
											?.enabled ?? false,
									trainingsSchema:
										data.value.toggles.find((toggle) => toggle.slug === "trainingsSchema")
											?.enabled ?? false,
								});

								if (coachId.value) {
									await reserveerApi.fitnessafspraken.wijzigAfspraak({
										...response.data,
										status: "aankomend",
										trainerId: coachId.value,
									});
								}
							} else {
								if (gymId.value !== afspraak.vestigingId || coachId.value !== afspraak.trainerId) {
									await reserveerApi.fitnessafspraken.wijzigAfspraak({
										...afspraak,
										vestigingId: gymId.value,
										trainerId: coachId.value,
									});
								}

								const newToggleValues = {
									doelOpstellen:
										data.value.toggles.find((toggle) => toggle.slug === "doelOpstellen")?.enabled ??
										false,
									meting:
										data.value.toggles.find((toggle) => toggle.slug === "meting")?.enabled ?? false,
									kickOff:
										data.value.toggles.find((toggle) => toggle.slug === "kickOff")?.enabled ??
										false,
									personalTraining:
										data.value.toggles.find((toggle) => toggle.slug === "personalTraining")
											?.enabled ?? false,
									trainingsSchema:
										data.value.toggles.find((toggle) => toggle.slug === "trainingsSchema")
											?.enabled ?? false,
								};

								if (
									data.value.comments !== afspraak.aanwezigheid?.opmerkingen ||
									afspraak.aanwezigheid.doelOpstellen !== newToggleValues.doelOpstellen ||
									afspraak.aanwezigheid.meting !== newToggleValues.meting ||
									afspraak.aanwezigheid.kickOff !== newToggleValues.kickOff ||
									afspraak.aanwezigheid.personalTraining !== newToggleValues.personalTraining ||
									afspraak.aanwezigheid.trainingsSchema !== newToggleValues.trainingsSchema
								) {
									await reserveerApi.fitnessafspraken.updateAanwezigheid(afspraakId, {
										lidId: afspraak.aanwezigheid?.lidId ?? props.memberId,
										lidNaam:
											afspraak.aanwezigheid?.lidNaam ??
											`${memberInfo.value?.voornaam} ${memberInfo.value?.achternaam}`,
										status: "aangemeld",
										opmerkingen: data.value.comments,
										...newToggleValues,
									});
								}
							}

							toast.success("Afspraak aangepast", { id: TOAST_ID });
							root.$router.push({ name: "Afspraken" });
						} catch (error) {
							logger.error("Afspraak Aanpassen", error);
							toast.error(formatErrorMessage(error), { id: TOAST_ID });
							throw error;
						}
					},
				});
			} catch (error) {
				logger.error("Afspraak Aanpassen", error);
				toast.error(formatErrorMessage(error), { id: TOAST_ID });
				throw error;
			}
		}

		return {
			getDateFromDatum,
			getDateFromDatumAndTijd,
			isBefore,
			getFullName: getCoachName,
			showKickOff,
			memberInfo,
			appointment,
			gymId,
			gyms,
			coachId,
			coaches,
			disabledDates,
			date,
			status,
			slots,
			selectedSlot,
			data,
			submit,
		};
	},
});
